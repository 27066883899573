<template>
  <footer>
    <div class="container">
      <div class="margen div-flex">
        <div class="f1">
          <!--<img src="icons/logo-footer.svg" alt="">-->
          <v-lottie-player
            class="json"
            name="scooterAnim"
            loop
            path="json/footer.json"
          />
          <v-lottie-player
            class="json"
            name="scooterAnim"
            loop
            path="json/footer-rosa.json"
          />
        </div>
        <div class="f2 red">
          <a href="https://wa.link/9wpolh" target="_blank">
            <img
              id="nerdazulpp"
              class="nerdazul"
              src="icons/whatshapp.svg"
              alt=""
            />
            <img
              id="nerdazulpp"
              class="nerdrojo"
              src="icons/whatshapprojo.svg"
              alt=""
            />

            Whatsapp</a
          >
        </div>
        <div class="f3 red">
          <a
            href="https://www.instagram.com/nerd_studiodesign/"
            target="_blank"
          >
            <img src="icons/intagram.svg" class="nerdazul" alt="" />
            <img src="icons/intagramrojo.svg" class="nerdrojo" alt="" />
            Intagram</a
          >
        </div>
        <div class="f4 red">
          <a
            href="https://www.linkedin.com/company/nerd-studio-design/"
            target="_blank"
            ><img
              src="icons/iconlinkedin_nerdstudio.svg"
              class="nerdazul"
              alt=""
            />
            <img
              src="icons/iconlinkedin_nerdstudio_rojo.svg"
              class="nerdrojo"
              alt=""
            />
            Linkedin</a
          >
        </div>
        <div class="f5">
          <a href="mailto:hello@nerdstudio.design">hello@nerdstudio.design</a>
        </div>
      </div>
    </div>

    <div class="container footer-responsive">
      <div>
        <a href="mailto:hello@nerdstudio.design">hello@nerdstudio.design</a>
        <br />
        <a
          target="blank"
          href="https://wa.me/+51942464074?text=Hola Studio Design, me pueden compartir información de los servicios"
          ><span>(51) 942 464 074 </span></a
        >
      </div>
      <div class="img">
        <img src="icons/logo-footer.svg" alt="" />
      </div>
      <div class="redes-mobile">
        <a
          href="https://www.instagram.com/nerd_studiodesign/"
          target="_blank"
          class="ints"
        >
          <img src="icons/intagram.svg" class="nerdazul" alt="" />
          <img src="icons/intagramrojo.svg" class="nerdrojo" alt="" />
        </a>
        <a
          href="https://www.linkedin.com/company/nerd-studio-design/"
          target="_blank"
        >
          <img
            src="icons/iconlinkedin_nerdstudio.svg"
            class="nerdazul"
            alt=""
          />
          <img
            src="icons/iconlinkedin_nerdstudio_rojo.svg"
            class="nerdrojo"
            alt=""
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  name: "Footer",
  components: {
    vLottiePlayer: VueLottiePlayer,
  },
};
</script>
