<template>
  <section class="digital" id="abouts">
    <div class="container">
      <div class="margen">
        <div class="digital-text">
          <h2 class="titlemov1">DIGITAL MINDS</h2>
          <h2 class="titlemov">About us</h2>
          <p class="p2">
            The digital age has changed the way we consume and communicate. Our
            process does not contemplate anything random. We find what makes
            each brand special and align it with what our clients are looking
            for to make them memorable.
          </p>
          <p class="p1">
            The digital age has changed the way we consume and communicate. Our
            process does not contemplate anything random. <br />
            <br />
            We find what makes each brand special and align it with what our
            clients are looking for to make them memorable.
          </p>
          <img id="nerdazulc" class="nerdazul" src="icons/logo7.svg" alt="" />
          <img
            id="nerdrojoc"
            class="imgvector nerdrojo"
            src="icons/logo07rojo.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Digital",
};
</script>
