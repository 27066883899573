<template>
  <div class="form">
    <div class="title">LETS TALK?</div>
    <p>We will be happy to make your project come true</p>
    <form
      class="formulario containerform"
      id="fs-frm"
      name="complaintForm"
      accept-charset="utf-8"
      ref="form"
      v-on:submit="onSubmit"
    >
      <fieldset id="fs-frm-inputs">
        <div class="form-group">
          <input
            type="text"
            autocomplete="off"
            v-model="form.nombre"
            class="form-input nombreInput"
            name="nombre"
            id="full-name"
            required=""
          />
          <label for="form.nombre" class="form-label">Your name</label>
        </div>

        <div class="form-group">
          <input
            type="email"
            @blur="validateEmail($event)"
            autocomplete="off"
            v-model="form.email"
            class="form-input emailInput"
            name="email"
            :class="{ 'focus-visible': focused }"
            id="email-address"
            required=""
          />
          <label for="form.email" class="form-label">Email</label>
        </div>

        <div class="form-group textarea">
          <textarea
            rows="6"
            autocomplete="off"
            v-model="form.mensaje"
            class="form-input mensajeInput"
            name="mensaje"
            id="complaint"
            required=""
          ></textarea>
          <label for="form.mensaje" class="form-label"
            >Tell about your project</label
          >
        </div>

        <div class="text-center">
          <div class="hoverEvent formBoton">
            <span>
              <input class="btn button send" type="submit" value="Send" />
            </span>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import axios from "axios";
const querystring = require("querystring");

export default {
  name: "Form",

  data() {
    return {
      sent: false,
      res: "",
      focused: false,
      form: {
        nombre: "",
        email: "",
        mensaje: "",
      },
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();

      axios
        .post(
          "https://nerdstudio.design/php/thanks.php",
          querystring.stringify(this.form)
        )
        .then((res) => {
          this.form.nombre = "";
          this.form.email = "";
          this.form.mensaje = "";
          this.sent = true;
          this.focused = false;
          this.$emit("getDataForm", this.sent);
        });
    },

    validateEmail: function (e) {
      const name = e.target.value;
      if (name.length > 0) {
        this.focused = true;
      } else {
        this.focused = false;
      }

      //this.email(e,name);
    },
  },
};
</script>
