<template>
  <section class="servicess" id="services">
    <div class="container" id="servicesContent">
      <div class="margen">
        <div class="services-title">
          <h2>WHAT WE DO</h2>
          <p>
            We are a studio focused on branding and digital design that finds
            the ideal balance between creativity, media, innovation and strategy
            so that all our brands can connect with people. We are a studio
            where every idea seeks to solve a problem and where innovation
            action has a purpose.
          </p>
        </div>
        <div class="services-cards">
          <div class="wrap ver1">
            <div class="tarjeta-wrap">
              <div class="tarjeta">
                <div class="adelante card1">
                  <div class="servicess-img">
                    <!--<img id="nerdazult" class="icon-brand" src="icons/branding.svg" alt="">
                                        <img id="nerdrojot" class="icon-brand nerdrojo" src="icons/brandingrojo.svg" alt="">
                                        <img id="nerdazuly" class="flecha2" src="icons/flecha2.svg" alt="">
                                        <img id="nerdrojoy" class="flecha2 nerdrojo" src="icons/flecharojaa.svg" alt="">-->

                    <div class="icon">
                      <!--<img src="icons/branding-services.svg" alt="">-->
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/branding.json"
                      />
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/branding-rosa.json"
                      />
                    </div>
                    <div class="servicess-title">
                      <h2 class="titlebran">branding</h2>
                    </div>
                  </div>
                  <a v-on:click="setData('branding', branding)"></a>
                </div>
                <div class="atras">
                  <div class="card1">
                    <h2>branding</h2>
                    <ul>
                      <li v-for="itemBra in branding" :key="itemBra">
                        {{ itemBra.name }}
                      </li>
                    </ul>
                    <div class="card1-button contacty">
                      <a
                        v-on:click="setData('branding', branding)"
                        class="nav-toggler6"
                        ><span>Start a proyect</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tarjeta-wrap medium">
              <div class="tarjeta">
                <div class="adelante card1">
                  <div class="servicess-img ux">
                    <!--<img id="nerdazulh" class="icon-brand logoux" src="icons/ux-ui.svg">
                                        <img id="nerdrojoh" class="icon-brand nerdrojo" src="icons/uxroja.svg" alt="">
                                        <img id="nerdazulm" class="flecha2 flecha2-1" src="icons/flecha2.svg" alt="">
                                        <img id="nerdrojom" class="flecha2 flecha2-1 nerdrojo" src="icons/flecharojaa.svg" alt="">-->
                    <div class="icon">
                      <!--<img src="icons/design_services.svg">-->
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/design.json"
                      />
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/design-rosa.json"
                      />
                    </div>
                    <div class="servicess-title">
                      <h2 class="titlebran">ux/ui design</h2>
                    </div>
                  </div>
                  <a v-on:click="setData('ux/ui design', design)"></a>
                </div>
                <div class="atras">
                  <div class="card1 ux">
                    <h2>ux/ui design</h2>
                    <ul>
                      <li v-for="itemDe in design" :key="itemDe">
                        {{ itemDe.name }}
                      </li>
                    </ul>
                    <div class="card1-button contactu">
                      <a
                        v-on:click="setData('ux/ui design', design)"
                        href="#contact"
                        ><span>Start a proyect</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tarjeta-wrap">
              <div class="tarjeta">
                <div class="adelante card1">
                  <div class="servicess-img creation">
                    <!--<img id="nerdazulb" class="icon-brand" src="icons/creation.svg" alt="">
                                        <img id="nerdrojob" class="icon-brand nerdrojo" src="icons/creationroja.svg" alt="">
                                        <img id="nerdazulv" class="flecha2 flecha3-1" src="icons/flecha2.svg" alt="">
                                        <img id="nerdrojov" class="flecha2 flecha3-1 nerdrojo" src="icons/flecharojaa.svg" alt="">-->
                    <div class="icon">
                      <!--<img src="icons/creation_services.svg">-->
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/creation.json"
                      />
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/creation-rosa.json"
                      />
                    </div>
                    <div class="servicess-title">
                      <h2 class="titlebran">Creation</h2>
                    </div>
                  </div>
                  <a v-on:click="setData('creation', creation)"></a>
                </div>
                <div class="atras">
                  <div class="card1 creation">
                    <h2>Creation</h2>
                    <ul>
                      <li v-for="itemCre in creation" :key="itemCre">
                        {{ itemCre.name }}
                      </li>
                    </ul>
                    <div class="card1-button contacti">
                      <a
                        v-on:click="setData('creation', creation)"
                        href="#contact"
                        ><span>Start a proyect</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap ver2">
            <div class="tarjeta-wrap">
              <div class="tarjeta">
                <div class="adelante card1">
                  <div class="servicess-img">
                    <!--<img id="nerdazult" class="icon-brand" src="icons/branding.svg" alt="">
                                        <img id="nerdrojot" class="icon-brand nerdrojo" src="icons/brandingrojo.svg" alt="">
                                        <img id="nerdazuly" class="flecha2" src="icons/flecha2.svg" alt="">
                                        <img id="nerdrojoy" class="flecha2 nerdrojo" src="icons/flecharojaa.svg" alt="">-->

                    <div class="icon">
                      <!--<img src="icons/branding-services.svg" alt="">-->
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/branding.json"
                      />
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/branding-rosa.json"
                      />
                    </div>
                    <div class="servicess-title">
                      <h2 class="titlebran">branding</h2>
                    </div>
                  </div>
                  <a v-on:click="setData2('branding', branding)"></a>
                </div>
                <div class="atras">
                  <div class="card1">
                    <h2>branding</h2>
                    <ul>
                      <li v-for="itemBra in branding" :key="itemBra">
                        {{ itemBra.name }}
                      </li>
                    </ul>
                    <div class="card1-button contacty">
                      <a
                        v-on:click="setData('branding', branding)"
                        class="nav-toggler6"
                        ><span>Start a proyect</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tarjeta-wrap medium">
              <div class="tarjeta">
                <div class="adelante card1">
                  <div class="servicess-img ux">
                    <!--<img id="nerdazulh" class="icon-brand logoux" src="icons/ux-ui.svg">
                                        <img id="nerdrojoh" class="icon-brand nerdrojo" src="icons/uxroja.svg" alt="">
                                        <img id="nerdazulm" class="flecha2 flecha2-1" src="icons/flecha2.svg" alt="">
                                        <img id="nerdrojom" class="flecha2 flecha2-1 nerdrojo" src="icons/flecharojaa.svg" alt="">-->
                    <div class="icon">
                      <!--<img src="icons/design_services.svg">-->
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/design.json"
                      />
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/design-rosa.json"
                      />
                    </div>
                    <div class="servicess-title">
                      <h2 class="titlebran">ux/ui design</h2>
                    </div>
                  </div>
                  <a v-on:click="setData2('ux/ui design', design)"></a>
                </div>
                <div class="atras">
                  <div class="card1 ux">
                    <h2>ux/ui design</h2>
                    <ul>
                      <li v-for="itemDe in design" :key="itemDe">
                        {{ itemDe.name }}
                      </li>
                    </ul>
                    <div class="card1-button contactu">
                      <a
                        v-on:click="setData('ux/ui design', design)"
                        href="#contact"
                        ><span>Start a proyect</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tarjeta-wrap">
              <div class="tarjeta">
                <div class="adelante card1">
                  <div class="servicess-img creation">
                    <!--<img id="nerdazulb" class="icon-brand" src="icons/creation.svg" alt="">
                                        <img id="nerdrojob" class="icon-brand nerdrojo" src="icons/creationroja.svg" alt="">
                                        <img id="nerdazulv" class="flecha2 flecha3-1" src="icons/flecha2.svg" alt="">
                                        <img id="nerdrojov" class="flecha2 flecha3-1 nerdrojo" src="icons/flecharojaa.svg" alt="">-->
                    <div class="icon">
                      <!--<img src="icons/creation_services.svg">-->
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/creation.json"
                      />
                      <v-lottie-player
                        name="scooterAnim"
                        loop
                        path="json/creation-rosa.json"
                      />
                    </div>
                    <div class="servicess-title">
                      <h2 class="titlebran">Creation</h2>
                    </div>
                  </div>
                  <a v-on:click="setData2('creation', creation)"></a>
                </div>
                <div class="atras">
                  <div class="card1 creation">
                    <h2>Creation</h2>
                    <ul>
                      <li v-for="itemCre in creation" :key="itemCre">
                        {{ itemCre.name }}
                      </li>
                    </ul>
                    <div class="card1-button contacti">
                      <a
                        v-on:click="setData('creation', creation)"
                        href="#contact"
                        ><span>Start a proyect</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import datosServices from "../assets/json/services.json";
import VueLottiePlayer from "vue-lottie-player";
export default {
  name: "Services",
  components: {
    vLottiePlayer: VueLottiePlayer,
  },
  el: "#servicesContent",
  data() {
    return {
      branding: [
        { name: "Brand Strategy" },
        { name: "Identity Visual" },
        { name: "Tag & Slogas" },
        { name: "Naming" },
        { name: "Packaging" },
        { name: "Art Direction" },
      ],
      creation: [
        { name: "Creative Concept" },
        { name: "Digital Campaigns" },
        { name: "Photography" },
        { name: "Stop Motion" },
        { name: "ATL / BTL" },
        { name: "Social Media" },
      ],
      design: [
        { name: "Websites" },
        { name: "Landing Pages" },
        { name: "E Commerce" },
        { name: "Digital Products" },
      ],
      data: [],
    };
  },
  methods: {
    setData(title, list) {
      this.data = [];
      this.data.push({ titulo: title, lista: list, form: 1 });
      this.$emit("getData", this.data);
    },
    setData2(title, list) {
      this.data = [];
      this.data.push({ titulo: title, lista: list });
      this.$emit("getData", this.data);
    },
  },
};
</script>
