<template>
  <nav>
    <div class="iconomenu" id="iconomenu">
      <img src="icons/Group05.svg" class="logo-responsive" />
      <img src="icons/hamburguesa.svg" alt="" />
    </div>
    <div class="enlaces uno" id="enlaces">
      <div class="enlacescabeza">
        <div class="arriba">
          <h2>Studio Desgin</h2>
          <div class="menuclose" id="asd">
            <a><img src="icons/flechablanca.svg" alt="" /></a>
          </div>
        </div>
        <div class="menumedio">
          <img src="icons/Vectormenu.svg" alt="" />
          <ul>
            <li><a href="index.html">Home</a></li>
            <li><a href="#portfolio" id="work">Work</a></li>
            <li><a href="#services" id="whatwedo">What we do</a></li>
            <li><a href="#abouts" id="about">About</a></li>
            <li>
              <a href="#contact-us" id="contact" class="contactm">Contact</a>
            </li>
          </ul>
        </div>
        <!--<div class="navbutton">
                        <a href="index.html">
                            <h2>SCHEDULE A MEETING</h2> 
                            <img src="icons/webcamwhite.svg" alt="">
                        </a>
                    </div>-->
      </div>
    </div>
    <div class="">
      <div class="margen"></div>
      <div class="margen-left"></div>
      <div class="margen-right"></div>
      <div class="margen-bottom"></div>
      <div>
        <div class="top menuprinc">
          <MarqueeText :repeat="100" :duration="0.8">
            WORK <span>-</span>
          </MarqueeText>
        </div>
        <div class="navleft menuprinc">
          <div class="separar"></div>
          <div class="about">
            <a href="#abouts"
              >A<br />B<br />O<br />U<br />T<br /><br />U<br />S</a
            >
          </div>

          <div class="separar"></div>

          <div class="services">
            <a href="#services"
              >S<br />E<br />R<br />V<br />I<br />C<br />E<br />S</a
            >
          </div>
          <div class="separar"></div>

          <div class="socialMedia ing">
            <a class="a1" href="https://nerdstudio.design/es"
              ><span><img src="icons/arrow-bottom.svg" /></span>ES</a
            >
          </div>
          <div class="separar"></div>
        </div>
        <div class="down">
          <MarqueeText :repeat="100" :duration="0.8" :reverse="true">
            WORK <span>-</span>
          </MarqueeText>
        </div>
        <div class="navRight menuprinc">
          <div class="separar"></div>
          <div class="contact">
            <a href="#contact-us"
              >C<br />O<br />N<br />T<br />A<br />C<br />T<br />
              <br />U<br />S</a
            >
          </div>
          <div class="separar"></div>
          <div class="portfolio">
            <a href="#portfolio"
              >P<br />O<br />R<br />T<br />F<br />O<br />L<br />I<br />O</a
            >
          </div>
          <div class="separar"></div>
          <div class="dark">
            <a class="change-theme switchDark activado" id="switch"
              ><img src="icons/elipse.svg" alt="" /><img
                src="icons/elipse-dark.svg"
                class="elipse-dark"
                alt=""
            /></a>
          </div>
          <div class="separar"></div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
//import Vue from 'vue';
import MarqueeText from "vue-marquee-text-component";
//Vue.component('marquee-text', MarqueeText);
export default {
  name: "Nav",
  components: {
    MarqueeText: MarqueeText,
  },
};
</script>
