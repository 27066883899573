<template>
  <div class="wrapper-main" v-bind:class="showSlide ? 'open' : ''">
    <header>
      <Nav />
      <transition name="slide">
        <div>
          <section class="contacto" v-bind:class="showSlide ? 'open' : ''">
            <div class="titlePoup" v-show="!toggleThanks">Studio.Desgin</div>
            <div class="container">
              <div class="margen">
                <div class="flexcont">
                  <div class="close">
                    <a v-on:click="change">
                      <img
                        id="nerdazulmm"
                        src="icons/newclose.svg"
                        alt="x-close"
                      />
                    </a>
                  </div>

                  <div v-show="!toggleThanks">
                    <div v-show="!toggleTalk" class="content-contacto">
                      <div class="title-contacto">{{ titleContacto }}</div>
                      <ul>
                        <li v-for="list in listContacto" :key="list">
                          {{ list.name }}
                        </li>
                      </ul>

                      <a @click="toggleTalk = !toggleTalk" class="hoverEvent"
                        ><span>Lets Talk?</span></a
                      >
                    </div>
                    <Form v-show="toggleTalk" @getDataForm="getDataForm" />
                  </div>

                  <div v-show="toggleThanks">
                    <Thanks class="thanks" @changeHome="changeHome" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </transition>
    </header>

    <main>
      <Home @getData="getData" />

      <Portfolio @getData="getData" />

      <Services @getData="getData" />

      <Digital />

      <Partnerds />

      <section id="contact-us" class="container">
        <section class="container">
          <Form @getDataForm="getDataForm" />
        </section>
      </section>

      <Footer />
    </main>
  </div>
</template>

<script>
import Thanks from "./components/Thanks.vue";
import Home from "./components/Home.vue";
import Services from "./components/Services.vue";
import Form from "./components/Form.vue";
import Nav from "./components/Nav.vue";
import Portfolio from "./components/Portfolio.vue";
import Digital from "./components/Digital.vue";
import Partnerds from "./components/Partnerds.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Thanks,
    Home,
    Services,
    Form,
    Nav,
    Footer,
    Partnerds,
    Digital,
    Portfolio,
  },
  data() {
    return {
      titleContacto: " ",
      listContacto: " ",
      toggleTalk: false,
      toggleThanks: false,
      showSlide: false,
      changeColor: false,
    };
  },
  methods: {
    getData(data) {
      this.titleContacto = data[0].titulo;
      this.listContacto = data[0].lista;
      this.showSlide = true;
      if (data[0].titulo === "form") {
        this.toggleTalk = true;
      }

      if (data[0].form == 1) {
        this.toggleTalk = true;
      }
    },
    change() {
      this.showSlide = false;
      this.toggleTalk = false;
      this.toggleThanks = false;
    },

    getDataForm(data) {
      this.showSlide = true;
      this.toggleThanks = !this.toggleThanks;
      this.toggleTalk = false;
    },

    changeHome() {
      this.showSlide = false;
      this.toggleTalk = false;
      this.toggleThanks = false;
    },
  },
};
</script>
