<template>
  <section>
    <div class="home">
      <div class="home-text">
        <h2 class="cabeza">we are</h2>
        <img id="nerdazul" class="nerdazul" src="icons/logo.svg" alt="" />
        <img id="nerdrojo" class="nerdrojo" src="icons/nerdrojo.svg" alt="" />
        <h2 class="pie">Studio Design</h2>
      </div>
      <div class="home-text2">
        <div class="text">
          <p>
            Creative and proactive team that enjoys creating memorable brands
            over time and digital experiences.
          </p>
        </div>
        <div class="botones">
          <ul>
            <li class="branss">
              <a v-on:click="setData('branding', branding)" class="hoverEvent"
                ><span>Branding</span></a
              >
            </li>
            <li class="bransss">
              <a v-on:click="setData('design', design)" class="hoverEvent"
                ><span>UX/UI Design</span></a
              >
            </li>
            <li class="bransssss">
              <a v-on:click="setData('creation', creation)" class="hoverEvent"
                ><span>Creative</span></a
              >
            </li>
          </ul>
        </div>
        <div class="icon">
          <div class="icon-hover">
            <div class="icon-map contactr">
              <a v-on:click="setData('form', form)">
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  path="json/arco.json"
                />
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  path="json/arco-rosa.json"
                />
              </a>
              <!--<a href="#contact" class="nav-toggler5">
                                <div class="img-top">
                                    <img id="nerdazulq" class="nerdazul img1" src="icons/newstaraproyect.svg" alt="">
                                    <img id="nerdrojoq" class="nerdrojo" src="icons/newstaraproyectrojo.svg" alt="">
                                </div>
                                <div class="img-bot">
                                    <v-lottie-player name="scooterAnim" loop path="json/arco.json"/>

                                    <img id="nerdazule" class="nerdazul" src="icons/logo-design.svg" alt="logo-personaje">
                                    <img id="nerdrojoe" class="nerdrojo" src="icons/iconorojo.svg" alt="">
                                </div>
                                <div class="img-flecha">
                                    <img id="nerdazulw" class="nerdazul" src="icons/flecha.svg" alt="logo-personaje">
                                    <img id="nerdrojow" class="nerdrojo" src="icons/flecharojo.svg" alt="">
                                </div>
                            </a>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  name: "Home",
  components: {
    vLottiePlayer: VueLottiePlayer,
  },
  data() {
    return {
      branding: [
        { name: "Brand Strategy" },
        { name: "Identity Visual" },
        { name: "Tag & Slogas" },
        { name: "Naming" },
        { name: "Packaging" },
        { name: "Art Direction" },
      ],
      creation: [
        { name: "Creative Concept" },
        { name: "Digital Campaigns" },
        { name: "Photography" },
        { name: "Stop Motion" },
        { name: "ATL / BTL" },
        { name: "Social Media" },
      ],
      design: [
        { name: "Websites" },
        { name: "Landing Pages" },
        { name: "E Commerce" },
        { name: "Digital Products" },
      ],
      data: [],
    };
  },
  methods: {
    setData(title, list) {
      this.data = [];
      this.data.push({ titulo: title, lista: list });
      this.$emit("getData", this.data);
    },
  },
};
</script>
