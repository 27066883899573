<template>
  <section class="portfolio selecttWork" id="portfolio">
    <div class="container">
      <div class="title">
        <h2>SELECT WORK</h2>
      </div>
      <div class="cards ocul">
        <div class="card cardpad">
          <div class="imgss imho">
            <a id="evento-port" v-on:click="setData('creation', creation)">
              <img src="icons/work1_yaw.jpg" alt="" />
              <span>
                <!--<img src="videos/work1_yaw.gif" alt="">-->
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  width="100%"
                  height="100%"
                  path="json/work1_yaw.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Logo design, Visual identity, Packaging,</h3>
              <h2>YAW!</h2>
            </div>
            <div class="fruts">
              <img src="icons/🍋 🍎.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="imgss imm">
            <a id="evento-port" v-on:click="setData('creation', creation)">
              <img src="icons/work2_Statkraft.png" alt="" />
              <span>
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  width="100%"
                  height="100%"
                  path="json/work2_Statkraft.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Creative Campaign, Visual Design, Web Design</h3>
              <h2>Statkraft</h2>
            </div>
            <div class="fruts">
              <img src="icons/⚡ 🌎.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="cards cardsbot ocul">
        <div class="card cardpad">
          <div class="imgss imhoo">
            <a id="evento-port" v-on:click="setData('creation', creation)">
              <img src="icons/work3_olivos.jpg" alt="" />
              <span>
                <v-lottie-player
                  name="scooterAnim"
                  width="100%"
                  height="100%"
                  loop
                  path="json/olivos.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Visual identity, Packanging, Social Media Stratregy</h3>
              <h2>OLIVOS DEL SUR</h2>
            </div>
            <div class="fruts">
              <img src="icons/🍋 🍎.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="imgss immm">
            <a id="evento-port" v-on:click="setData('creation', creation)">
              <img src="icons/work4_neo.png" alt="" />
              <span>
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  width="100%"
                  height="100%"
                  path="json/neo-projects.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Visual identity, Brand Guidelines, Brand Applications</h3>
              <h2>NEO PROJECTS</h2>
            </div>
            <div class="fruts">
              <img src="icons/⚡ 🌎.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="cards mostrar">
        <div class="card cardpad">
          <div class="imgss imho">
            <a id="evento-port">
              <img src="icons/work1_yaw.jpg" alt="" />
              <span>
                <!--<img src="videos/work1_yaw.gif" alt="">-->
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  width="100%"
                  height="100%"
                  path="json/work1_yaw.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Identity brand, Packcanging</h3>
              <h2>YAW!</h2>
            </div>
            <div class="fruts">
              <img src="icons/🍋 🍎.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="imgss imm">
            <a id="evento-port">
              <img src="icons/work2_Statkraft.png" alt="" />
              <span>
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  width="100%"
                  height="100%"
                  path="json/work2_Statkraft.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Strategy, Campaign</h3>
              <h2>Statkraft</h2>
            </div>
            <div class="fruts">
              <img src="icons/⚡ 🌎.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="cards cardsbot mostrar">
        <div class="card cardpad">
          <div class="imgss imhoo">
            <a id="evento-port">
              <img src="icons/work3_olivos.jpg" alt="" />
              <span>
                <v-lottie-player
                  name="scooterAnim"
                  width="100%"
                  height="100%"
                  loop
                  path="json/olivos.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>Branding, Campaing</h3>
              <h2>OLIVOS DEL SUR</h2>
            </div>
            <div class="fruts">
              <img src="icons/🍋 🍎.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="imgss immm">
            <a id="evento-port">
              <img src="icons/work4_neo.png" alt="" />
              <span>
                <v-lottie-player
                  name="scooterAnim"
                  loop
                  width="100%"
                  height="100%"
                  path="json/neo-projects.json"
                />
              </span>
            </a>
          </div>
          <div class="cards-text hoverEvent">
            <div>
              <h3>UX Design, UI Design and Development</h3>
              <h2>NEO PROJECTS</h2>
            </div>
            <div class="fruts">
              <img src="icons/⚡ 🌎.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  name: "Portfolio",
  components: {
    vLottiePlayer: VueLottiePlayer,
  },
  el: "#portfolio",
  data() {
    return {
      branding: [
        { name: "Brand Strategy" },
        { name: "Identity Visual" },
        { name: "Visual Design" },
        { name: "Naming" },
        { name: "Branded" },
        { name: "Art Direction" },
      ],
      creation: [
        { name: "Creative Concept" },
        { name: "Corporate Design" },
        { name: "Graphica Design" },
        { name: "Social Media Content" },
        { name: "Content Brand" },
        { name: "Stop Motion" },
      ],
      design: [
        { name: "Websites" },
        { name: "Landing Pages" },
        { name: "E Commerce" },
        { name: "Digital Products" },
      ],
      data: [],
    };
  },
  methods: {
    setData(title, list) {
      this.data = [];
      this.data.push({ titulo: title, lista: list, form: 1 });
      this.$emit("getData", this.data);
    },
  },
};
// var mediaqueryList2 = window.matchMedia("(max-width: 640px)");
// const btnport = document.getElementById("evento-port");
// if (mediaqueryList2.matches) {
// document.querySelector(".pageresize").style.visibility = "hidden";
// document.getElementById("evento-port").removeAttribute("click");
// btnport.addEventListener("click", function (e) {
//   e.preventDefault();
// });
// }
</script>
