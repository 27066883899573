<template>
  <div class="thanks">
    <div class="content">
      <div class="title">
        thank <br />
        you
      </div>
      <p>In a few moments we are going to contact you</p>
      <a v-on:click="changeHome" class="hoverEvent"><span>Go Home</span></a>
      <a
        target="_blank"
        class="hoverEvent"
        href="https://www.instagram.com/nerd_studiodesign/"
        ><span>Go Instagram</span></a
      >
    </div>

    <div class="down">
      <MarqueeText :repeat="100" :duration="0.8" :reverse="true">
        WORK <span>-</span>
      </MarqueeText>
    </div>
  </div>
</template>
<script>
import MarqueeText from "vue-marquee-text-component";
export default {
  name: "Thanks",
  components: {
    MarqueeText: MarqueeText,
  },
  data() {
    return {
      data: true,
    };
  },

  methods: {
    changeHome() {
      this.$emit("changeHome", this.data);
    },
  },
};
</script>
