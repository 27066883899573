<template>
  <section class="partnerds">
    <div class="container">
      <div class="margen">
        <div>
          <img
            id="nerdazulz"
            class="partnerds-logo nerdazul"
            src="icons/partnerds.svg"
            alt=""
          />
          <img
            id="nerdrojoz"
            class="partnerds-logo nerdrojo"
            src="icons/partnerdsrojo.svg"
            alt=""
          />
        </div>
        <h2>Brands</h2>
        <p>
          They trust us to inject creativity and strategy into their brands.
        </p>
        <div class="wrapper">
          <div class="box b b1">
            <img
              id="nerdazulii"
              class="nerdazul"
              src="icons/logospray.svg"
              alt=""
            />
            <img
              id="nerdrojoii"
              class="nerdrojo"
              src="icons/logosprayrojo.svg"
              alt=""
            />
          </div>
          <div class="box c c1">
            <img
              id="nerdazuljj"
              class="nerdazul"
              src="icons/logovivanda.svg"
              alt=""
            />
            <img
              id="nerdrojojj"
              class="nerdrojo"
              src="icons/logovivandarojo.svg"
              alt=""
            />
          </div>
          <div class="box d d1">
            <img
              id="nerdazulkk"
              class="nerdazul"
              src="icons/logolinea1.svg"
              alt=""
            />
            <img
              id="nerdrojokk"
              class="nerdrojo"
              src="icons/logolinea1rojo.svg"
              alt=""
            />
          </div>
          <div class="box h">
            <img
              id="nerdazulgg"
              class="nerdazul"
              src="icons/logostatkraft.svg"
              alt=""
            />
            <img
              id="nerdrojogg"
              class="nerdrojo"
              src="icons/logostatkraftrojo.svg"
              alt=""
            />
          </div>
          <div class="box e">
            <img
              id="nerdazuldd"
              class="nerdazul"
              src="icons/subway.svg"
              alt=""
            />
            <img
              id="nerdrojodd"
              class="nerdrojo"
              src="icons/subwayrojo.svg"
              alt=""
            />
          </div>
          <div class="box f">
            <img
              id="nerdazulee"
              class="nerdazul"
              src="icons/logo-sodimac.svg"
              alt=""
            />
            <img
              id="nerdrojoee"
              class="nerdrojo"
              src="icons/logo-sodimacrojo.svg"
              alt=""
            />
          </div>
          <div class="box a a1">
            <img
              id="nerdazulhh"
              class="nerdazul"
              src="icons/logooxxo.svg"
              alt=""
            />
            <img
              id="nerdrojohh"
              class="nerdrojo"
              src="icons/logooxxorojo.svg"
              alt=""
            />
          </div>
          <div class="box g">
            <img
              id="nerdazulff"
              class="nerdazul"
              src="icons/logo-reebok.svg"
              alt=""
            />
            <img
              id="nerdrojoff"
              class="nerdrojo"
              src="icons/logo-reebokrojo.svg"
              alt=""
            />
          </div>
          <div class="box a">
            <img
              id="nerdazuli"
              class="nerdazul"
              src="icons/logo-classroom.svg"
              alt=""
            />
            <img
              id="nerdrojoi"
              class="nerdrojo"
              src="icons/logo-classroomrojo.svg"
              alt=""
            />
          </div>
          <div class="box a">
            <img
              id="nerdazuli"
              class="nerdazul"
              src="icons/logo-vivaHome.svg"
              alt=""
            />
            <img
              id="nerdrojoi"
              class="nerdrojo"
              src="icons/logo-vivaHomerojo.svg"
              alt=""
            />
          </div>
          <div class="box a">
            <img
              id="nerdazuli"
              class="nerdazul"
              src="icons/logo-itawGroup.svg"
              alt=""
            />
            <img
              id="nerdrojoi"
              class="nerdrojo"
              src="icons/logo-itawGrouprojo.svg"
              alt=""
            />
          </div>
          <div class="box a">
            <img
              id="nerdazuli"
              class="nerdazul"
              src="icons/logo-queop.svg"
              alt=""
            />
            <img
              id="nerdrojoi"
              class="nerdrojo"
              src="icons/logo-queoprojo.svg"
              alt=""
            />
          </div>
        </div>

        <carousel :items-to-show="1" :autoplay="2000">
          <slide :key="slide">
            <div class="box b b1">
              <img
                id="nerdazulii"
                class="nerdazul"
                src="icons/logospray.svg"
                alt=""
              />
              <img
                id="nerdrojoii"
                class="nerdrojo"
                src="icons/logosprayrojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box c c1">
              <img
                id="nerdazuljj"
                class="nerdazul"
                src="icons/logovivanda.svg"
                alt=""
              />
              <img
                id="nerdrojojj"
                class="nerdrojo"
                src="icons/logovivandarojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box d d1">
              <img
                id="nerdazulkk"
                class="nerdazul"
                src="icons/logolinea1.svg"
                alt=""
              />
              <img
                id="nerdrojokk"
                class="nerdrojo"
                src="icons/logolinea1rojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box h">
              <img
                id="nerdazulgg"
                class="nerdazul"
                src="icons/logostatkraft.svg"
                alt=""
              />
              <img
                id="nerdrojogg"
                class="nerdrojo"
                src="icons/logostatkraftrojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box e">
              <img
                id="nerdazuldd"
                class="nerdazul"
                src="icons/subway.svg"
                alt=""
              />
              <img
                id="nerdrojodd"
                class="nerdrojo"
                src="icons/subwayrojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box f">
              <img
                id="nerdazulee"
                class="nerdazul"
                src="icons/logo-sodimac.svg"
                alt=""
              />
              <img
                id="nerdrojoee"
                class="nerdrojo"
                src="icons/logo-sodimacrojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box a a1">
              <img
                id="nerdazulhh"
                class="nerdazul"
                src="icons/logooxxo.svg"
                alt=""
              />
              <img
                id="nerdrojohh"
                class="nerdrojo"
                src="icons/logooxxorojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box g">
              <img
                id="nerdazulff"
                class="nerdazul"
                src="icons/logo-reebok.svg"
                alt=""
              />
              <img
                id="nerdrojoff"
                class="nerdrojo"
                src="icons/logo-reebokrojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box a">
              <img
                id="nerdazuli"
                class="nerdazul"
                src="icons/logo-classroom.svg"
                alt=""
              />
              <img
                id="nerdrojoi"
                class="nerdrojo"
                src="icons/logo-classroomrojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box a">
              <img
                id="nerdazuli"
                class="nerdazul"
                src="icons/logo-vivaHome.svg"
                alt=""
              />
              <img
                id="nerdrojoi"
                class="nerdrojo"
                src="icons/logo-vivaHomerojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box a">
              <img
                id="nerdazuli"
                class="nerdazul"
                src="icons/logo-itawGroup.svg"
                alt=""
              />
              <img
                id="nerdrojoi"
                class="nerdrojo"
                src="icons/logo-itawGrouprojo.svg"
                alt=""
              />
            </div>
          </slide>
          <slide :key="slide">
            <div class="box a">
              <img
                id="nerdazuli"
                class="nerdazul"
                src="icons/logo-queop.svg"
                alt=""
              />
              <img
                id="nerdrojoi"
                class="nerdrojo"
                src="icons/logo-queoprojo.svg"
                alt=""
              />
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
export default {
  name: "Partnerds",
  components: {
    Carousel,
    Slide,
  },
};
</script>
